<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-card
          class="text-left"
        >
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              {{ incubatorTitle }}
            </h3>
          </b-card-header>
          <b-tabs
            v-model="activeTab"
            nav-class="mb-3"
            class="h-scroll-tabs"
            pills
            @input="updateHash"
          >
            <!--            <b-tab-->
            <!--              class="text-left"-->
            <!--              href="Agreements"-->
            <!--              title="Agreements"-->
            <!--              lazy-->
            <!--            >-->
            <!--              $Lorem-->
            <!--            </b-tab>-->
            <b-tab
              class="text-left"
              href="EMeet"
              title="E-Meet"
              lazy
            >
              <EMeet />
            </b-tab>
            <b-tab
              class="text-left"
              href="Programs"
              title="Programs"
              lazy
            >
              <Programs />
            </b-tab>
            <b-tab
              class="text-left"
              href="Milestones"
              title="Milestones"
              lazy
            >
              <Milestones />
            </b-tab>
            <b-tab
              class="text-left"
              href="Requests"
              title="Requests"
              lazy
            >
              <Requests />
            </b-tab>
            <b-tab
              class="text-left"
              href="Partners"
              title="Partners"
              lazy
            >
              <Partners />
            </b-tab>
            <b-tab
              class="text-left"
              href="Mentors"
              title="Mentors"
              lazy
            >
              <Mentors />
            </b-tab>
            <!--            <b-tab-->
            <!--              class="text-left"-->
            <!--              href="Campaigns"-->
            <!--              title="Campaigns"-->
            <!--              lazy-->
            <!--            >-->
            <!--              $Lorem-->
            <!--            </b-tab>-->
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BTab, BTabs,
} from 'bootstrap-vue'

import Milestones from '@/views/startup-portal/pages/incubators/incubator-manager/Milestones.vue'
import Requests from '@/views/startup-portal/pages/incubators/incubator-manager/Requests.vue'
import Programs from '@/views/startup-portal/pages/incubators/incubator-manager/Programs.vue'
import Partners from '@/views/startup-portal/pages/incubators/incubator-manager/Partners.vue'
import Mentors from '@/views/startup-portal/pages/incubators/incubator-manager/Mentors.vue'
import EMeet from '@/views/startup-portal/pages/incubators/incubator-manager/Emeet.vue'
import gql from 'graphql-tag'

export default {
  components: {
    EMeet,
    Programs,
    Partners,
    Requests,
    Milestones,
    Mentors,
    BCard,
    BTab,
    BTabs,
    BCardHeader,
    BButton,
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        // '#About',
        // '#Agreements',
        '#EMeet',
        '#Programs',
        '#Milestones',
        '#Requests',
        '#Partners',
        '#Mentors',
        '#Campaigns'],
      incubatorTitle: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    incubatorTitle: {
      query: gql`
        query getUsersAssociationtable($id: Int!) {
          users_associationtable_by_pk(id: $id) {
            id
            usersOrganizationtableByIncubator {
              title
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      update: data => data.users_associationtable_by_pk.usersOrganizationtableByIncubator.title,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
